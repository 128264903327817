<script setup lang="ts">
import UpgradeModal from '~/components/modals/UpgradeModal.vue';

const userStore = useUserStore();
const modal = useModal();

const { isEnabled } = userStore;

onMounted(() => {
  if (!isEnabled('sdr-agent')) {
    modal.open(UpgradeModal, {
      feature: 'Agents',
      plan: 'Enterprise',
      description: 'automate your sales outreach.',
    });
  }
});
</script>
<template>
  <NuxtLayout name="dashboard" :containerless="true">
    <!-- TOP BAR -->
    <div
      class="px-8 py-4 sticky top-0 left-0 flex flex-row border-b border-gray-200 justify-between"
    >
      <p class="main-title">Agents</p>
      <UTooltip text="Agent Studio coming soon...">
        <UButton
          color="primary"
          variant="solid"
          label="New"
          icon="i-ph-plus"
          size="lg"
          disabled
        />
      </UTooltip>
    </div>

    <!-- HEADER -->
    <UContainer :ui="{ base: 'h-fit flex flex-col' }">
      <div
        class="flex flex-col gap-2 p-8 w-[450px] border border-gray-200 rounded-xl"
      >
        <UIcon name="i-respell-sdr-agent" class="w-16 h-16" />
        <UBadge color="primary" variant="soft">NEW</UBadge>
        <p class="title">SDR Agent</p>
        <p class="body dimmed py-2">
          Automate prospecting, lead generation, and initial outreach while
          maintaining a personalized touch. Seamlessly integrate with your
          existing tools to boost your pipeline and free up your human SDRs for
          high-value tasks.
        </p>
        <UButton
          color="white"
          variant="solid"
          label="Use"
          size="lg"
          trailing-icon="i-ph-caret-right"
          to="/agent/sdr/configure"
        />
      </div>

      <div class="flex flex-col w-full py-24 gap-2 items-center">
        <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />
        <p class="subtitle">More agents coming soon...</p>
      </div>
    </UContainer>
  </NuxtLayout>
</template>
